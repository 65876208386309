.contained-testimonial-two-column-content {
	.testimonial-quote {
		background-color: tint($primary, 90%);
		padding: 2rem;
		quote {
			font-size: 1.25rem;
		}
	}
	.testimonial-quote-vertical {
		background-color: tint($primary, 90%);
		padding: 2rem;
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
		quote {
			font-size: 1.25rem;
		}
	}
}