// Section Margin Large
.section-margin-lg {
	@include section-margin-lg;
}
.section-margin-bottom-lg {
	@include section-margin-bottom-lg;
}
.section-margin-top-lg {
	@include section-margin-top-lg;
}

// Section Margin Medium
.section-margin-md {
	@include section-margin-md;
}
.section-margin-bottom-md {
	@include section-margin-bottom-md;
}
.section-margin-top-md {
	@include section-margin-top-md;
}

// Section Margin Small
.section-margin-sm {
	@include section-margin-sm;
}
.section-margin-bottom-sm {
	@include section-margin-bottom-sm;
}
.section-margin-top-sm {
	@include section-margin-top-sm;
}

// Section padding Large
.section-padding-lg {
	@include section-padding-lg;
}
.section-padding-bottom-lg {
	@include section-padding-bottom-lg;
}
.section-padding-top-lg {
	@include section-padding-top-lg;
}

// Section padding Medium
.section-padding-md {
	@include section-padding-md;
}
.section-padding-bottom-md {
	@include section-padding-bottom-md;
}
.section-padding-top-md {
	@include section-padding-top-md;
}

// Section padding Small
.section-padding-sm {
	@include section-padding-sm;
}
.section-padding-bottom-sm {
	@include section-padding-bottom-sm;
}
.section-padding-top-sm {
	@include section-padding-top-sm;
}

// Positioning
.p-relative {
	position: relative;
}
.p-absolute {
	padding: absolute;
}
.p-fixed {
	padding: fixed;
}
.grecaptcha-badge{
	display: none;
}