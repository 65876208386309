a {
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
}
p, a {
	@include media-breakpoint-up(lg) { 
		font-size: calc(#{$font-size-base} * 0.9);
		line-height: calc(#{$font-size-base} * (1.8 * 0.9));
	}
	@include media-breakpoint-down(lg) { 
		font-size: calc(#{$font-size-base} * 0.9);
		line-height: calc(#{$font-size-base} * (1.8 * 0.9));
	}
	@include media-breakpoint-down(md) { 
		font-size: calc(#{$font-size-base} * 0.8);
		line-height: calc(#{$font-size-base} * (1.8 * 0.8));
	}
	@include media-breakpoint-down(sm) { 
		font-size: calc(#{$font-size-base} * 0.7);
		line-height: calc(#{$font-size-base} * (1.8 * 0.7));
	}
}

