// This is global styling. Only amend if you wish to change global swiper dots.

$swiper-button-base-size: 12px;
$swiper-button-triple-size: calc( #{$swiper-button-base-size * 3} );

.swiper-pagination-bullet {
	margin: 0.8rem;
	.hero-slider{
		.swiper-pagination-bullet-active {
			background-color: $white;
		}
	}
	.awards-slider-contained-1 {
		.swiper-pagination-bullet{
		}
		}
		.swiper-pagination-bullet-active {
			background-color: $primary;
		}
	}