.full-width-map{
	border-radius: 0.75rem 0 0 0.75rem;
	height:500px;
}
.full-width-map-iframe{
	border:0; 
	border-radius:0.75rem;
}
.half-map {
	border-radius: 0.75rem 0 0 0.75rem;
}

.half-map-content {
	padding: 50px 60px;
	border-radius: 0 0.75rem 0.75rem 0;
}
@include media-breakpoint-down (xl) {
	.half-map-content {
		border-radius: 0 0 0.75rem 0.75rem;
	}
	.half-map {
		border-radius:0.75rem 0.75rem 0 0;
	}
}

@include media-breakpoint-down (md) {
	.half-map-content {
		p {
			font-size: calc(1rem * 0.7);
		}
		i {
			font-size: 25px;
			margin-top: 30px;
		}
		h4 {
			font-size: 1.2rem;
		}
	}
}

.border-radius-tican{
	border-radius: 0.75rem;
}

.gm-style {
	.gm-style-iw-t {
		&::after {
			background: $dark-blue;
		}
	}

	.gm-style-iw-c {
		background-color: $white;
		color: $dark-blue;
		border-radius: 0 !important;
		padding: 28px 24px !important;
	}

	.gm-style-iw-d {
		overflow: hidden !important;
	}

	.gm-ui-hover-effect {
		background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 4L20 20M20 4L4 20' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A") !important;
		background-position: left bottom !important;
		background-repeat: no-repeat !important;
		background-size: 12px !important;
		height: 24px !important;
		width: 24px !important;
		right: 3px !important;
		top: -3px !important;

		& > img {
			display: none !important;
		}
	}
}