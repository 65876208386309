.news-listing-1-with-image {
	background-color: $bg-grey;
	position: relative;
    .card {
        border: .25rem solid $white;
    }
	.card-title {
		color: $primary;
		font-weight: 600;
	}
	.published {
		color: $light-grey;
		font-size: 0.85rem;
	}
	&:after {
		background-color: $primary;
		top: 0;
		bottom: 40%;
		position: absolute;
		left: 0;
		right: 0;
		content: '';
		z-index: -1;
	}
}
.news-pagination{
		margin:auto 5px;
		min-width:300px;

}