.footer {
	padding: 5rem 0;
	.quick-link-list {
		list-style-type: none;
		padding-left: 0;
	}
}
.sub-footer {
	background-color: darken($primary, 5%);
	ul {
		list-style-type: none;
		margin-bottom: 0;
		padding: 2rem 0;
 		li {
			display: inline-flex;
			a {
				color: darken($white, 25%);
				padding-bottom: 0;
				margin-bottom: 0;
				font-size: 0.8rem; 
				padding-right: 1.5rem;
				transition: ease all 0.25s;
				&:hover {
					color: $white;
				}
			}
		}
	}
	a {
		color: darken($white, 25%);
		transition: ease all 0.25s;
		font-size: 0.8rem; 
		&:hover {
			color: $white;
		}
	}
}

.lower-footer{
	background-color: $dark-blue;

	a{
	color:$white;
	font-size: 0.85rem;
	&:hover{
		color:$secondary;
	}
	}
	p{
	color:$white;	
	font-size: 0.85rem;	
	}
}

.footer-logo{
	border-radius: 100%;
}
.footer-wesbite-padding{
	@include media-breakpoint-down(sm) {
      padding-top: 0;
      a {
      	padding-top: 0;
      }
    }
}