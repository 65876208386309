// Navigation
.nav-hide-on-scroll {
    background: $white;
    height: 135px;
    position: fixed;
    top: 0;
    transition: top 0.2s ease-in-out;
    width: 100%;
    z-index: 100;
    box-shadow: 0px 3px 16px 3px rgba(0,0,0,0.05);
    -webkit-box-shadow: 0px 3px 16px 3px rgba(0,0,0,0.05); 
    @include media-breakpoint-down(md) { 
		height: 100px;
	}
}
/*! purgecss start ignore */
.nav-up {
    top: -135px;
    @include media-breakpoint-down(md) { 
    	top: -100px;
	}
}
/*! purgecss end ignore */