.contact-form {
	margin: 3rem 0;
	.form-check-input {
	    border-radius: 0.15rem;
	    margin-top: 0.35em;
	    height: 0.75rem;
		width: 0.75rem;
	}
	label {
		font-weight: 600;
		@include media-breakpoint-up(lg) { 
			font-size: calc(#{$font-size-base} * 0.9);
			line-height: calc(#{$font-size-base} * (1.8 * 1));
		}
		@include media-breakpoint-down(lg) { 
			font-size: calc(#{$font-size-base} * 0.9);
			line-height: calc(#{$font-size-base} * (1.8 * 0.9));
		}
		@include media-breakpoint-down(md) { 
			font-size: calc(#{$font-size-base} * 0.8);
			line-height: calc(#{$font-size-base} * (1.8 * 0.8));
		}
		@include media-breakpoint-down(sm) { 
			font-size: calc(#{$font-size-base} * 0.7);
			line-height: calc(#{$font-size-base} * (1.8 * 0.7));
		}
	}
	.form-control {
		background-color: $bg-grey;
		border: 0;
		border-radius: 0;
		padding: 1rem;
		@include media-breakpoint-up(lg) { 
			font-size: calc(#{$font-size-base} * 0.9);
			line-height: calc(#{$font-size-base} * (1.8 * 1));
		}
		@include media-breakpoint-down(lg) { 
			font-size: calc(#{$font-size-base} * 0.9);
			line-height: calc(#{$font-size-base} * (1.8 * 0.9));
		}
		@include media-breakpoint-down(md) { 
			font-size: calc(#{$font-size-base} * 0.8);
			line-height: calc(#{$font-size-base} * (1.8 * 0.8));
		}
		@include media-breakpoint-down(sm) { 
			font-size: calc(#{$font-size-base} * 0.7);
			line-height: calc(#{$font-size-base} * (1.8 * 0.7));
		}
	}
}