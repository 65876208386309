// Global Styling
* {
	&:focus {
		outline: none;
	}
}
// Global styling End

main {
	margin-top: 135px;
	@include media-breakpoint-down(md) { 
		margin-top: 100px;
	}
}