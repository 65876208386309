// Typography
$font-family-sans-serif: 'Work Sans', sans-serif;
$font-family-sans-serif: 'Work Sans', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;
@mixin font-awesome {
	font-family: "Font Awesome 5 Pro";
}
@mixin nav-item-font {
	@include media-breakpoint-up(xxl) { 
		font-size: calc(#{$font-size-base} * 1);
		line-height: calc(#{$line-height-base}rem * 0.9);
	}
	@include media-breakpoint-down(xxl) { 
		font-size: calc(#{$font-size-base} * 0.9);
		line-height: calc(#{$line-height-base}rem * 0.85);
	}
	@include media-breakpoint-down(lg) { 
		font-size: calc(#{$font-size-base} * 0.85);
		line-height: calc(#{$line-height-base}rem * 0.8);
	}
	@include media-breakpoint-down(md) { 
		font-size: calc(#{$font-size-base} * 0.75);
		line-height: calc(#{$line-height-base}rem * 0.75);
	}
	@include media-breakpoint-down(sm) { 
		font-size: calc(#{$font-size-base} * 0.65);
		line-height: calc(#{$line-height-base}rem * 0.65);
	}
}
