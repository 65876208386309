.background-grey-row-50 {
	position: absolute;
	bottom:0;
	top: calc(50% - 1.5rem);
	width:100%;
	background-color: #F2F2F2;
	z-index: -1;
}
.background-grey-row-75 {
	position: absolute;
	bottom:0;
	top: 8rem;
	width:100%;
	background-color: #F2F2F2;
	z-index: -1;
}