.card-container {
	.card {
		margin-bottom: 2rem;
		border-radius: 0.75rem;
		&.card-top-radius{
			border-radius: 0.75rem 0.75rem 0 0;
		}
		.card-body {
			padding: 0rem 2rem;
			margin: 3rem 0;
			border-radius: 0.75rem;
			position: relative;
			&.red-border-left{
				&:before{
				content:"";
				border-left: 2px $secondary solid;
				height: 100%;
				position: absolute;
				left: 0;
				bottom: 0;
				top: 0;
			}
			}
			.card-title {
				color: $primary;
				@include media-breakpoint-up(lg) { 
					font-size: calc(#{$font-size-base} * 1.5);
					line-height: calc(#{$font-size-base} * (1.4 * 1.5));
				}
				@include media-breakpoint-down(lg) { 
					font-size: calc(#{$font-size-base} * 1.25);
					line-height: calc(#{$font-size-base} * (1.4 * 1.25));
				}
				@include media-breakpoint-down(md) { 
					font-size: calc(#{$font-size-base} * 1.1);
					line-height: calc(#{$font-size-base} * (1.4 * 1.1));
				}
				@include media-breakpoint-down(sm) { 
					font-size: calc(#{$font-size-base} * 1);
					line-height: calc(#{$font-size-base} * (1.4 * 1));
				}
			}
			.card-title-container{
				h5{
					line-height: 1.15;
					margin-bottom: 30px;
					font-weight: bold;
				}

			}
			h5{
				line-height: 0.5;
				font-weight: bold;
			}
			.news-date{
					color: #999999;
				}
			p {
				
				@include media-breakpoint-up(lg) { 
					font-size: calc(#{$font-size-base} * 0.8);
					line-height: calc(#{$font-size-base} * (1.8 * 0.8));
				}
				@include media-breakpoint-down(lg) { 
					font-size: calc(#{$font-size-base} * 0.7);
					line-height: calc(#{$font-size-base} * (1.8 * 0.7));
				}
				@include media-breakpoint-down(md) { 
					font-size: calc(#{$font-size-base} * 0.65);
					line-height: calc(#{$font-size-base} * (1.8 * 0.65));
				}
				@include media-breakpoint-down(sm) { 
					font-size: calc(#{$font-size-base} * 0.6);
					line-height: calc(#{$font-size-base} * (1.8 * 0.6));
				}
			}
		}
		.news-row {
			.card-body {
				border-left:0;
			}
		}
		.card-img-top{
			border-radius: 0;
			&.rounded-card-img-top{
				border-radius: 0.75rem 0.75rem 0 0;
			}
		}
		.card-body{
			border-radius: 0;
		}
	}

}
