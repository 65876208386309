// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';
@import 'libraries/import';

// SCSS Imports
@import 'component-styling/accordion/accordion-master';
@import 'component-styling/awards-slider/awards-slider-master';
@import 'component-styling/buttons/buttons-master';
@import 'component-styling/burger-navigation/burger-navigation-master';
@import 'component-styling/cards/cards-master';
@import 'component-styling/content/content-master';
@import 'component-styling/contact-form/contact-form-master';
@import 'component-styling/downloads/downloads-master';
@import 'component-styling/footer/footer-master';
@import 'component-styling/general/general-master';
@import 'component-styling/hero-slider/hero-slider-master';
@import 'component-styling/navigation/navigation-master';
@import 'component-styling/newsletter-signup/newsletter-signup-master';
@import 'component-styling/swiper-utility/swiper-utility-master';
@import 'component-styling/typography/typography-master';
@import 'component-styling/utility/utility-colour';
@import 'component-styling/news/news-listing-1-with-image';
@import 'component-styling/gallery/gallery-master';
@import 'component-styling/location/location-style';
@import 'component-styling/grey-background/grey-background';
@import 'component-styling/return-bar/return-bar';
@import 'component-styling/image-text-col/image-text-col';
@import 'component-styling/breadcrumbs/breadcrumb';
