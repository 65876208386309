.hero-slider {
	.swiper-pagination {
		position: absolute;
	}
}
.swiper-button-prev-pale, .swiper-button-next {
    border-radius: calc( 36px );
    height: calc( 36px );
    width: calc( 36px );
}

.pale{
	background-color: white;
    color: #e72a2a;
}

.swiper-pagination-bullet-active {
    width: 14px;
    height: 14px;
    display: inline-block;
    border-radius: 50%;
    border: solid 1px #727272;
    background-color: $dark-grey;
    opacity: 1;
}