.downloads-contained-2-with-image {
	padding: 6rem 0;
	position: relative;
	@include media-breakpoint-down(sm) { 
			padding: 3rem 0;
		}
	&:after {
		background-color: $bg-grey;
		bottom: 50%;
		content: '';
	    height: 50%;
		left: 0;
		opacity: 1;
	    position: absolute;
	    right: 0;
	    top: 0;
	    width: 100%;
	    transition: ease all 0.25s;
	    z-index: -1;
	}
	.card-content {
		padding: 3rem;
		p, .card-title {
			color: $primary;
		}
		.card-title {
			font-weight: 600;
		}
	}
	.downloads-background-image {
		border-radius: 0 0.75rem 0.75rem 0;
		@include background-image-cover;
		height: 100%;
		@include media-breakpoint-down(lg) { 
			height: 250px;
			border-radius: 0.75rem 0.75rem 0 0;
		}
		&:hover {
			&:after {
				opacity: 0;
			}
		}
		.downloads-background-content {
			z-index: 1;
		}
	}
}