.hero-slider-fluid-shallow {
	position: relative;
	z-index: 0;
	.swiper-wrapper {
		z-index: -1;
	}
	.hero-shallow-banner-bg-image {
		@include background-image-cover;
		height: 400px;
	}
	.hero-slider-fluid-1-caption {
		position: absolute;
		width: 100%; 
 		z-index: 2;
 		* {
 			color: $white;
 		}
	}
}