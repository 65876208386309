// Section Margin Large
@mixin section-margin-lg {
	margin: 5rem 0;
}
@mixin section-margin-bottom-lg {
	margin-bottom: 5rem;
}
@mixin section-margin-top-lg {
	margin-top: 5rem;
}

// Section Margin Medium
@mixin section-margin-md {
	margin: 3rem 0;
}
@mixin section-margin-bottom-md {
	margin-bottom: 3rem;
}
@mixin section-margin-top-md {
	margin-top: 3rem;
}

// Section Margin Small
@mixin section-margin-sm {
	margin: 1.5rem 0;
}
@mixin section-margin-bottom-sm {
	margin-bottom: 1.5rem;
}
@mixin section-margin-top-sm {
	margin-top: 1.5rem;
}

// Section padding Large
@mixin section-padding-lg {
	padding: 5rem 0;
}
@mixin section-padding-bottom-lg {
	padding-bottom: 5rem;
}
@mixin section-padding-top-lg {
	padding-top: 5rem;
}

// Section padding Medium
@mixin section-padding-md {
	padding: 3rem 0;
}
@mixin section-padding-bottom-md {
	padding-bottom: 3rem;
}
@mixin section-padding-top-md {
	padding-top: 3rem;
}

// Section padding Small
@mixin section-padding-sm {
	padding: 1.5rem 0;
}
@mixin section-padding-bottom-sm {
	padding-bottom: 1.5rem;
}
@mixin section-padding-top-sm {
	padding-top: 1.5rem;
}

// Background border-image-source: 
@mixin background-image-cover {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
}