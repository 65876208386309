.awards-slider-contained-1 {
	.swiper-pagination-bullet{
		background: $light-grey;
	}
	.swiper-pagination-bullet-active{
		background: $dark-grey;
	}
	h2 {
		color: $primary;
	}
	.awards-slider-contained-1-pagination {
	}
	.awards-slider-contained-1-button-prev {
		left: -50px;
		@include media-breakpoint-down(lg) { 
			left: 10px;
		}
 	}
	.awards-slider-contained-1-button-next {
		right: -50px;
		@include media-breakpoint-down(lg) { 
			right: 10px;
		}
 	}
}