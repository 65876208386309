.return-bar {
	p{
		font-weight: 500;
		margin-bottom: 0;
	}
}

.fab {
	color: $white;
	&:hover{
		color:$secondary;
	}
}

.return-icons{
	display:flex;
	border:solid 4px white;
	align-items:center;
	justify-content:flex-end;
	p{
		margin-bottom: 0;
	}
}
.return-right-content {
	align-items:center;
	justify-content:flex-end;
}

@include media-breakpoint-down(lg) {
	.return-icons{
		display:flex;
		align-items:center;
		justify-content:center;
		border:solid 0px white;
	}
	.return-right-content {
		align-items:center;
		justify-content:center;
		.return-share{
			p{
				font-size: 1.2em;
			}
		}
				a{
					font-size: 1.3em;
				}
		}

		.return-button{
			justify-content:center;
			margin-bottom: 1.1em;
			margin-top: 0.8em;
		}
		.return-bar{
			margin-bottom: 1.3em;
		}
	}