.contained-content {
	.introduction {
		font-weight: 700;
	}
}
.white-bg-two-col{
	h2 {
		color: $primary;
	}
	p {
		color:#606060;
	}
}
.welcome-bg-logo{
	top:0;
	width: 45vw;
    min-width: 350px;
    max-width: 800px;
}

.shadow1 {
	box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%);
}