.downloads-contained-1 {
	.downloads-background-image {
		@include background-image-cover;
		height: 200px;
		&:after {
			background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
			bottom: 0;
			content: '';
		    height: 50%;
			left: 0;
			opacity: 1;
		    position: absolute;
		    right: 0;
		    top: 50%;
		    width: 100%;
		    transition: ease all 0.25s;
		    z-index: 0;
		}
		&:hover {
			&:after {
				opacity: 0;
			}
		}
		.downloads-background-content {
			z-index: 1;
		}
	}
}