h1 {
	@include media-breakpoint-down(lg) { 
		font-size: calc(2.5rem * 0.9);
	}
	@include media-breakpoint-down(md) { 
		font-size: calc(2.5rem * 0.8);
	}
	@include media-breakpoint-down(sm) { 
		font-size: calc(2.5rem * 0.6);
	}
	font-weight: 700;
}
h2 {
	@include media-breakpoint-down(lg) { 
		font-size: calc(2rem * 0.9);
	}
	@include media-breakpoint-down(md) { 
		font-size: calc(2rem * 0.8);
	}
	@include media-breakpoint-down(sm) { 
		font-size: calc(2rem * 0.6);
	}
	font-weight: 600;
}