.hero-slider-fluid {
	position: relative;
	z-index: 0;
	.swiper-wrapper {
		z-index: -1;
		.swiper-slide {
			&:before {
				background: linear-gradient(0deg, rgba(0,23,88,1) 0%, rgba(240,240,240,0) 100%);
				content: '';
				position: absolute;
				top: 50%;
				bottom: 0;
				left: 0;
				right: 0;
				z-index: 2;
			}
		}
	}
	.hero-shallow-banner-bg-image {
		@include background-image-cover;
		height: 400px;
	}
	.hero-slider-fluid-1-caption {
		position: absolute;
		width: 100%; 
 		z-index: 2;
 		* {
 			color: $white;
 		}
	}
}