.breadcrumb-item {
    & + .breadcrumb-item {
        display: flex;
        align-items:center;
        font-size: 0.75rem;
        &::before {
            @include font-awesome;
            content: '\f105';
            color: $secondary;
            font-size: 0.75rem;
        }
    }
    .home-breadcrumb-icon{
        display: flex;
        align-items:center;
        font-size: 0.75rem;
    }
}
.fa-home{
    &:before{
        font-size: 0.75rem;
    }
}