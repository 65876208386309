.footer-contained-1 {
	background-color: $primary;
	color: darken($white, 15%);
	border-left: 1px solid #EE3224;
	a {
		color: darken($white, 15%);
		transition: ease all 0.25s;
		&:hover {
			color: $white;
		}
	}
	address, p, a {
		font-size: 0.85rem;
	}
	h3 {
		color: $white;
		font-size: 1.25rem;
	}
	.quick-link-list {
		&.quick-link-list-two-columns {
			column-count: 2;
			@include media-breakpoint-down(md) {
				column-count: 1;
			}
		}
	}
	.contact-block {
		.social-links {
			margin-top: 1rem;
			a {
				align-items: center;
				background-color: $primary;
				border-radius: 50px;
				display: inline-flex;
				height: 40px;
				justify-content: left;
				width: 40px;
				i {
					color: $white;
					&:hover{
						color:$secondary;
					}
				}
			}
		}
	}
}