// This is global styling. Only amend if you wish to change global swiper utility arrows.

$swiper-button-base-size: 12px;
$swiper-button-triple-size: calc( #{$swiper-button-base-size * 3} );

.swiper-button-prev, .swiper-button-next {
	border-radius: $swiper-button-triple-size;
	height: $swiper-button-triple-size;
	width: $swiper-button-triple-size;
	&:after {
		font-size: $swiper-button-base-size;
	}
	&.primary {
 		background-color: $primary;
 		color: $white;
 	}
 	&.white {
 		background-color: $white;
 		color: $primary;
 		box-shadow: 0px 10px 10px 0 (rgba(0, 0, 0, 0.25));
 	}
}
.swiper-button-inline {
    display: inline-flex;
    margin: 0 1rem;
    position: relative;
    top: 0;
}
@include media-breakpoint-down(sm) {
	.swiper-button-prev, .swiper-button-next{
		    height: calc( 23px );
    width: calc( 23px );
	}
	}