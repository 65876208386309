// Navigation List Items
.nav-item-list {
	display: inline-flex;
	list-style-type: none;
	margin-bottom: 0;
	padding-left: 0;
	font-weight: 700;
	.nav-item {
		color: $primary;
		line-height: normal;
		text-transform: uppercase;
		a {
			@include nav-item-font;
		}
		&:hover {
			color: $primary-tint;
		}
		.active {
			color: $secondary;
		}
	}
}

.dropdown-menu {
	background-color: $primary;
	a {
		color:$white;
		font-weight: 600;
	}
}

@include media-breakpoint-down(sm) { 

	.mobile-toggle{
		.btn
		&:after {
			@include font-awesome;
			display:none;
		}
	}
}

.offcanvas {
	color: white;
	border-left:1px solid $secondary;
	overflow: scroll;

	li {
		color:$white;
		position: relative;
		&:hover{
			color:$secondary;
		}
	}
}
.offcanvas-body{
	a{
		font-size: 18px;
		color: white;
		background-color: $primary;
		display: flex;
		justify-content:space-between;
		align-items:center;	
		border-radius: 5px;
		-moz-transition: all .15s ease-in;
		-o-transition: all .15s ease-in;
		-webkit-transition: all .15s ease-in;
		transition: all .15s ease-in;
		&:after {
			font-family: "Font Awesome 5 Pro";
			content: "\F105";
			position: absolute;
			right: 1rem;
			z-index: 10;
			color: white;
			transition: ease all 0.25s;
			
		}
		&:hover{

			&:after {
				font-family: "Font Awesome 5 Pro";
				content: "\F105";
				position: absolute;
				right: 1rem;
				z-index: 10;
				color: white;
				transition: ease all 0.25s;

			}
		}
		
		&:hover{
			background-color: $secondary;
			color: $white;
		}

	}

	.btn{
		.btn:before {
			border-left: 0;
		}
	}

}
.offcanvas-address{
	p{
		font-size: 16px;
		line-height: 1.6;
	}
	a{
		font-size: 16px;
		line-height: 1.6;
		color: $primary;
		background-color: white;
		&:hover{
			background-color: white;
		}
	}
	.address-icon{
		color: $primary;
		    -moz-transition: all .15s ease-in;
    -o-transition: all .15s ease-in;
    -webkit-transition: all .15s ease-in;
    transition: all .15s ease-in;

    &:hover{
    	color:$secondary;
    }
	}
}