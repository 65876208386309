.btn {
	border: 0;
	border-radius: 0;
	font-weight: 600;
	font-size: 0.9rem;
	padding: 0.75rem 2.25rem 0.75rem 1.25rem;
	position: relative;
	text-transform: uppercase;
	text-align: left;
 	z-index: 2;
 	transition: ease all 0.25s;
	@include media-breakpoint-down(lg) { 
		font-size: calc(0.9rem * 0.9);
	}
	@include media-breakpoint-down(md) { 
		font-size: calc(0.9rem * 0.8);
	}
	@include media-breakpoint-down(sm) { 
		font-size: calc(0.9rem * 0.7);
	}
	&:before {
		background-color: $secondary;
		bottom: 0;
		content: '';
		left: 0;
		position: absolute;
		right: calc(100% - 3px);
		top: 0;
		transition: ease all 0.25s;
		z-index: -1;
	}
	&:after {
		@include font-awesome;
		content: '\f105';
 		position: absolute;
		right: 1rem;
		z-index: 10;
		transition: ease all 0.25s;
	}
	&.no-after {
		padding: 0.75rem 1.25rem 0.75rem 1.25rem;
		&:hover {
			padding: 0.75rem 1.25rem 0.75rem 1.25rem;	
		}
		&:after {
			display: none;
		}
	}
	&.btn-return {
		padding: 0.75rem 1.25rem 0.75rem 2.25rem;
		&:after {
			@include font-awesome;
			content: '\f104';
	 		position: absolute;
			left: 1rem;
			z-index: 10;
			transition: ease all 0.25s;
		}
	}
	&.btn-fixed-width {
		width: 150px;
	}
	&:hover {
		padding-right: 2.75rem;
		&:before {
			left: 3px;
			right: 0;
		}
		&:after {
			right: 0.75rem;
		}
	}
}
.btn-secondary {
	background-color: $bg-grey;
	color: $primary;
	 &:hover {
 		color: $white;
 	}
}
.btn-wo {
	background-color: $white;
	font-weight: 500;
 	&:hover {
 		color: $white;
 	}
}